require('bootstrap-datepicker');
require('bootstrap/js/dist/tooltip');

require('datatables.net/js/jquery.dataTables.js');
require('datatables.net-bs4/js/dataTables.bootstrap4.js');
require('@/src/js/vendors/plugins/datatables.init.js');
require('datatables.net-autofill/js/dataTables.autoFill.min.js');
require('datatables.net-autofill-bs4/js/autoFill.bootstrap4.min.js');
require('jszip/dist/jszip.min.js');
require('pdfmake/build/pdfmake.min.js');
require('pdfmake/build/vfs_fonts.js');
require('datatables.net-buttons/js/dataTables.buttons.min.js');
require('datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.flash.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-colreorder/js/dataTables.colReorder.min.js');
require('datatables.net-fixedcolumns/js/dataTables.fixedColumns.min.js');
require('datatables.net-fixedheader/js/dataTables.fixedHeader.min.js');
require('datatables.net-keytable/js/dataTables.keyTable.min.js');
require('datatables.net-responsive/js/dataTables.responsive.min.js');
require('datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js');
require('datatables.net-rowgroup/js/dataTables.rowGroup.min.js');
require('datatables.net-rowreorder/js/dataTables.rowReorder.min.js');
require('datatables.net-scroller/js/dataTables.scroller.min.js');
require('datatables.net-select/js/dataTables.select.min.js');


require('./datatables.scss');